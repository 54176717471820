import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ReactMarkdown from 'react-markdown'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import SwiperJS from '../components/SwiperJS';
import Img from 'react-cloudinary-lazy-image';

const ProjectTemplate = ({ data, pageContext }) => {
  
  SwiperCore.use([Navigation, Pagination, Autoplay ]);

const { project, categories } = data
const projectFields = project.frontmatter
const categorie = categories.edges.find(x => x.node.frontmatter.url === projectFields.categorie[0] || null).node.frontmatter;

const swiperFields = projectFields.photos?.filter(f => f.slider === "1").sort((a, b) => a.position - b.position).map(m => ({
  url: projectFields.url,
  nom: projectFields.nom,
  photo: m.photo,
  categorie: categorie
}))

const renderProjetInfo = (label, field) => {
  let visible = false;
  visible = field && field !== '' && true
  return visible ? `${label} : ${field}` : null;
}

const renderSliderText = (photos) => {
let imgSegment, imgFile, imgFolder;
const sliderPhotos = photos?.filter(f => f.slider === "2");
    if (sliderPhotos?.length === 1) {
    imgSegment = sliderPhotos[0].photo.split('/');
    imgFile = imgSegment.pop();
    imgFolder = imgSegment.pop();
      return (<Img
      cloudName={'groupea'}
      imageName={'projets/' + imgFolder + '/' + imgFile}
      fluid={{
        maxWidth: 1920
      }}
      style={{
        height: '100vh'
      }}
      alt={imgFile}
      quality={false}
      />)
    } else {
  return (<Swiper
    navigation
    pagination={{
      clickable: true
    }}
    slidesPerView={1}
    spaceBetween={30}
     >
  { sliderPhotos?.filter(f => f.slider === "2").map((el, index) => {
    imgSegment = el.photo.split('/');
    imgFile = imgSegment.pop();
    imgFolder = imgSegment.pop();
    return(
      <SwiperSlide key={index}>
        <Img
          cloudName={'groupea'}
          imageName={'projets/' + imgFolder + '/' + imgFile}
          fluid={{
            maxWidth: 600,
            maxHeight: 1200  
          }}
          style={{
            width: '100%'                   
        }}
          alt={imgFile}
          quality={false}
        />
      </SwiperSlide>)
  })
  }
</Swiper>)
}
}

return (
    <Layout pageContext={pageContext}>
    <div>
      <section className="grille">
        <div className="row no-gutters">
          <div className="col" id="vignette" >
            <SwiperJS swiperFields={swiperFields} naviguable={false} ></SwiperJS>   
          </div>
        </div>

      </section>
      <section className="post_content">
        <div className="container">
          <div className="row no-gutters">
          <div className="col col-md-7 col-lg-8 pr-4 projet-col-gauche">
              <ReactMarkdown children={projectFields.description.replace(/\\/gi, '  ')}
                escapeHtml={false} />
                </div>
              <div className="col col-md-5 col-lg-4 projet-col-droite">
                <div className="projet-infos">
                  <div>{ renderProjetInfo('Client', projectFields.client) }</div>
                  <div>{ renderProjetInfo('Ville', projectFields.ville) }</div>
                  <div>{ renderProjetInfo('Année de réalisation', projectFields.annee) }</div>             
                  <div>{ renderProjetInfo('Équipe', projectFields.equipe) }</div>
                  <div>{ renderProjetInfo('Faits saillants', projectFields.portee) }</div>
                  <div>{ renderProjetInfo('Photos', projectFields.creditPhoto) }</div>
                </div>
                <div className="projet-slider2">
                { projectFields.photos && renderSliderText(projectFields.photos)
              
}
              </div>
              </div>
            </div>
          </div>
      </section>
    </div>
    </Layout>
  )
}


export default ProjectTemplate

export const projectPageQuery = graphql`
  query ProjectPageByID($id: String!) {
    categories: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "categories"}}}, sort: {fields: [frontmatter___position], order: ASC}) {
      edges {
        node {
          frontmatter {
            collectionKey
            url
            nom            
          }          
        }
      }
    }
    project: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        url
        nom
        description
        categorie
        client
        ville
        annee
        equipe
        portee
        creditPhoto
        photos {
          position
          photo
          slider
        }
      }
    }
  }
`

